@import "variables";
@import "mixins";

.title {
  margin: 0 20px;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 15px 0 !important;

  @include pc {
    margin: 0 0 0 4px;
  }
}

.container {
  width: 100%;
  margin: 40px auto 60px;

  @include pc {
    width: 1140px;
  }
}

.table {
  margin-top: 16px;

  .row {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-top: 1px solid $lightGray;

    @include pc {
      padding: 12px 0 12px 16px;
    }

    &:last-child {
      border-bottom: 1px solid $lightGray;
    }

    &:nth-child(even) {
      background-color: $superLightGray;
    }

    .tableHead {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      font-size: 13px;
      font-weight: 600;
      color: $blue;

      @include pc {
        flex-direction: row;
        gap: 8px;
      }

      .newAccent {
        padding: 4px 15px;
        font-size: 11px;
        color: $blue;
        background-color: $superLightBlue;
        border-radius: 3px;

        @include pc {
          padding: 4px 10px;
        }
      }

      .date {
        white-space: pre;
      }
    }

    .link {
      padding-left: 16px;
      font-size: 14px;

      @include pc {
        transition: $hover-transition;
        &:hover {
          text-decoration: underline;
          color: $blue;
        }
      }
    }
  }
}
